import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "../components/style.scss";
import "../components/columns.scss";
import Helmet from "react-helmet";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { FaWhatsapp } from "react-icons/fa";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "images/autohemoterapia_distancia.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageTwo: file(relativePath: { eq: "images/autohemoterapia_a_distancia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageThree: file(relativePath: { eq: "images/tratamiento_autohemoterapia_distancia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <div>
      <Helmet>
        <title>Tratamiento de Autohemoterapia a distancia</title>
        <meta
          name="description"
          content="¿Buscas tratamiento de autohemoterapia? Nosotros te la podemos llevar a cualquier parte del mundo."
        ></meta>
        <meta name="robots" content="index,follow"></meta>
        <meta
          property="og:title"
          content="Autohemoterapia a distancia"
        />
        <meta
          property="og:description"
          content="¿Buscas tratamiento de autohemoterapia? Nosotros te la podemos llevar a cualquier parte del mundo."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dpzoehc6i/image/upload/v1582235228/photo_2020-02-20_15-45-58_wb5bdm.jpg"
        />
        <meta
          property="og:url"
          content="https://autohemoterapia.mx/tratamiento-a-distancia"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <a
        href="https://api.whatsapp.com/send?phone=5215539994763&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20tratamiento%20a%20distancia%20de%20Autohemoterapia.%20"
        className="float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={45} className="my-float" />
      </a>
      <section className="hero is-fullheight atlas-1">
        <Navbar />
        <div className="hero-body">
          <div className="container">
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-6">
                <h1 className="subtitle is-2 is-size-5-mobile has-text-white has-text-weight-bold is-uppercase">
                  Tratamiento de Autohemoterapia a distancia
                </h1>
                <p className="subtitle is-5 is-size-6-mobile has-text-white">
                  ¿Quieres tener activado tu{" "}
                  <span className="has-text-white has-text-weight-bold">
                    Sistema Inmunológico?
                  </span>{" "}
                  No le des más vueltas y enfrenta enfermedades como diabetes, alergias,
                  acné, asma, artritis y lupus, entre otros.
                </p>
              </div>

              <div className="column"></div>
            </div>
            <div className="columns has-text-centered  has-text-centered-mobile">
              <div className="column"></div>
              <div className="column">
                <a
                  href="https://api.whatsapp.com/send?phone=5215539994763&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20tratamiento%20a%20distancia%20de%20Autohemoterapia.%20"
                  className="button is-danger is-inverted is-outlined is-rounded"
                >
                  PIDE TU TRATAMIENTO A DISTANCIA
                </a>
              </div>
              <div className="column">
                <a
                  href="#informacion"
                  className="button is-danger is-inverted is-outlined is-rounded"
                >
                  LEER MÁS
                </a>
              </div>
              <div className="column"></div>
            </div>
            <div className="columns">
              <div className="column">
                <p></p>
              </div>
              <div className="column is-3">
                <Img
                  fluid={data.imageOne.childImageSharp.fluid}
                  alt="Autohemoterapia a distancia"
                />
              </div>
              <div className="column"></div>
            </div>
          </div>
        </div>
        <div className="hero-foot"></div>
      </section>
      <section className="container" id="informacion">
        <div className="columns has-text-centered">
          <div className="column">
            <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase">
              Los beneficios de la Autohemoterapia, al alcance de todos
            </h2>
            <p className="subtitle is-5 is-size-6-mobile">
              ¡La autohemoterapia es una forma completamente natural para tener
              activas tus defensas!
            </p>
            <p className="subtitle is-5 is-size-6-mobile">
              Gracias a esto, tu cuerpo estará listo para no solo hacerle frente a las enfermedades que tristemente sufres, sino que también va a tener todo preparado
              y evitar otras enfermedades que te podrían dar.
            </p>
            <p className="subtitle is-5 is-size-6-mobile">
              Y si esto no fuera ya suficientemente bueno, al ser un tratamiento 100% natural y estar fundamentado en
              tu propio cuerpo, no hay ninguna clase de efectos secundarios.
            </p>
            <Img
              fluid={data.imageTwo.childImageSharp.fluid}
              alt="Autohemoterapia en la CDMX"
            />
            <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase">
              Tratamiento a distancia
            </h2>
            <p className="subtitle is-5 is-size-6-mobile">
              Si no vives en la Ciudad de México (o no quieres salir de casa en tiempos de COVID) y buscas el tratamiento de
              autohemoterapia, tenemos muy buenas noticias para tí.
            </p>
            <p className="subtitle is-5 is-size-6-mobile">
              Nosotros te ofrecemos valoración a distancia de tu situación vía Zoom y
              podremos darte un diagnóstico que marque el tratamiento a seguir para que tu salud comience a cambiar de inmediato. 
            </p>
            <p className="subtitle is-5 is-size-6-mobile">
              Además, te daremos una guía detallada de cómo aplicarte el tratamiento y te lo haremos llegar hasta la puerta de tu casa en máximo tres días después de que acreditemos tu pago. 
            </p>
            <p className="subtitle is-5 is-size-6-mobile">
              ¡Es momento de dejar de sufrir por todos esos problemas de salud que tienen años persiguiéndote! 
            </p>
            <p className="subtitle is-5 is-size-6-mobile">
              No dudes en ponerte en{" "}
              <a href="https://api.whatsapp.com/send?phone=5215539994763&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20tratamiento%20a%20distancia%20de%20Autohemoterapia.%20">contacto</a> con
              nosotros, vía telefónica o mediante Whatsapp.
            </p>
            <p className="subtitle is-5 is-size-6-mobile">
              ¡Estaremos muy felices de poderte ayudar!
            </p>
            <Img
              fluid={data.imageThree.childImageSharp.fluid}
              alt="Tratamiento de Autohemoterapia a distancia"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};